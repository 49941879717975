import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../../components/form/FormInput";
import { ApiURL } from "../../config";
import { AuthTokenContext } from "../../App";
import { checkAuth } from "../../components/helpers/authHelper";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  const AuthToken = useContext(AuthTokenContext);

  const checkUserAuth = () => {
    let auth = checkAuth(AuthToken.authToken);
    if (auth) {
      navigate("/account/");
    }
  };

  useEffect(() => {
    if (AuthToken.authToken) {
      checkUserAuth();
    }
  }, []);

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  const onSubmit = (e) => {
    setErrorMessage("");
    e.preventDefault();
    let newError = error;
    if (!username) {
      newError["username"] = "You did not enter your Email";
      setError(newError);
    }
    if (!password) {
      newError["password"] = "You did not enter your Password";
      setError(newError);
    }
    if (username && password) {
      setError([]);
      // embed login here
      fetch(`${ApiURL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ username, password }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === false) {
            localStorage.removeItem("token");
            let newError = [];
            setErrorMessage(res.details);
            setError(newError);
          } else {
            localStorage.setItem("token", res.token);
            AuthToken.login(res.token);
            AuthToken.setUserInfoCallback(res.userInfo);
            navigate("/account/");
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="dark">
      <div className="container-fluid">
        <div className="row min-vh-100 bg-100">
          <div className="col-6 d-none d-lg-block position-relative">
            <div
              className="bg-holder"
              style={{
                backgroundImage: "url(../../../assets/img/generic/14.jpg)",
                backgroundPosition: "50% 20%;",
              }}
            ></div>
          </div>
          <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
            <div className="row justify-content-center g-0">
              <div className="col-lg-9 col-xl-8 col-xxl-6">
                <div className="card">
                  <div className="card-header bg-circle-shape bg-shape text-center p-2">
                    <h4
                      className="font-sans-serif fw-bolder fs-4 z-1 position-relative link-light"
                      data-bs-theme="light"
                    >
                      Account Management
                    </h4>
                  </div>
                  <div className="card-body p-4">
                    <div className="row flex-between-center">
                      <div className="col-auto">
                        <h3>Login</h3>
                      </div>
                      <div className="col-auto fs--1 text-600">
                        <span className="mb-0 fw-semi-bold">New User?</span>{" "}
                        <span>
                          <Link to="/product/pricing">Create account</Link>
                        </span>
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            <div className="alert-message">{errorMessage}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    <form onSubmit={onFormSubmit}>
                      <FormInput
                        colSize="1"
                        type="email"
                        id="username"
                        label="Email"
                        name="username"
                        value={username}
                        placeholder="Enter your email"
                        autoComplete="username"
                        onChange={(type, e, field) =>
                          setUsername(e.target.value)
                        }
                        error={error["username"]}
                      />
                      <FormInput
                        colSize="1"
                        type="password"
                        id="password"
                        label="Password"
                        name="password"
                        value={password}
                        hideMeter={true}
                        placeholder="Enter your password"
                        autoComplete="current-password"
                        onChange={(type, e, field) =>
                          setPassword(e.target.value)
                        }
                        error={error["password"]}
                      >
                        <Link to="/auth/lost-password">Forgot password?</Link>
                      </FormInput>
                      <div className="container">
                        <div className="row">
                          <div className="col-6">
                            <Link className="btn btn-secondary mt-3" to="/">
                              <span
                                className="fas fa-chevron-left me-1"
                                data-fa-transform="shrink-4 down-1"
                              />
                              Home
                            </Link>
                          </div>
                          <div className="col-6 text-end">
                            <button
                              className="btn btn-primary mt-3"
                              onClick={onSubmit}
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
