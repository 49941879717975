import React, { useContext, useEffect, useState } from "react";
import { FormInput } from "cmation-formsbuilder";
import FormInputLocal from "../../components/form/FormInput";

import { AuthTokenContext } from "../../App";
import { ApiURL } from "../../config";

const Listing = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");

  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [image7, setImage7] = useState("");
  const [image8, setImage8] = useState("");
  const [image9, setImage9] = useState("");
  const [image10, setImage10] = useState("");
  const [tags, setTags] = useState([]);
  const [checkedTags, setCheckedTags] = useState([]);

  const [loading, setLoading] = useState(true);
  const [savingListing, setSavingListing] = useState(false);
  const AuthToken = useContext(AuthTokenContext);

  useEffect(() => {
    getListing().catch((err) => {
      console.log(err);
    });
  }, []);

  const getListing = async () => {
    return await fetch(`${ApiURL}/account/getListing`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken.authToken,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setData(res);
        }
      })
      .catch((err) => {});
  };

  const onSaveListing = async () => {
    // setLoading(true);
    setSavingListing(true);
    return await fetch(`${ApiURL}/account/saveListing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken.authToken,
      },
      body: JSON.stringify({
        name: name,
        description: description,
        address: address,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        website: website,
        facebook: facebook,
        twitter: twitter,
        instagram: instagram,
        youtube: youtube,
        image: image,
        image2: image2,
        image3: image3,
        image4: image4,
        image5: image5,
        image6: image6,
        image7: image7,
        image8: image8,
        image9: image9,
        image10: image10,
        tags: checkedTags,
      }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setSavingListing(false);
        if (res.status) {
          setSuccessMessage(res.message);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
        setSavingListing(false);
      });
  };

  const setData = (data) => {
    setName(data.listing.name);
    setDescription(data.listing.description);
    setAddress(data.listing.address);
    setAddress2(data.listing.address2);
    setCity(data.listing.city);
    setState(data.listing.state);
    setZip(data.listing.zip);
    setPhone(data.listing.phone);
    setWebsite(data.listing.website);
    setFacebook(data.listing.facebook);
    setTwitter(data.listing.twitter);
    setInstagram(data.listing.instagram);
    setYoutube(data.listing.youtube);
    setImage(data.listing.image);
    setImage2(data.listing.image2);
    setImage3(data.listing.image3);
    setImage4(data.listing.image4);
    setImage5(data.listing.image5);
    setImage6(data.listing.image6);
    setImage7(data.listing.image7);
    setImage8(data.listing.image8);
    setImage9(data.listing.image9);
    setImage10(data.listing.image10);
    setTags(data.tags);
  };

  const saveListing = () => {
    onSaveListing().catch((err) => {
      console.log(err);
    });
  };

  const handleTagChange = (e) => {
    let tmp = [];
    if (e.target.checked) {
      tmp = [...checkedTags, e.target.value];
    } else {
      tmp = checkedTags.filter((tag) => tag !== e.target.value);
    }
    setCheckedTags(tmp);
  };

  useEffect(() => {
    console.log("useEffect checkedTags", checkedTags);
  }, [checkedTags]);

  if (loading) {
    return <>Loading listing </>;
  }

  return (
    <>
      <h1>Manage Your Listing</h1>
      <p>If you are a new account your listing will remain inactive (not published) until you verify all the information of your listing, add at least 1 photo and add a description.</p>
      <div className="container">
        <div className="row position-relative">
          <h3 className="my-4">Business Information</h3>
          {successMessage && (
            <div className="col-12">
              <div className="alert alert-success">{successMessage}</div>
            </div>
          )}
          <FormInput
            label="Business Name"
            name="name"
            value={name}
            onChange={(value) => setName(value)}
            colSize={1}
          />
          <FormInput
            label="Address"
            name="address"
            value={address}
            onChange={(value) => setAddress(value)}
            colSize={2}
          />
          <FormInput
            label="Address (cont.)"
            name="address2"
            value={address2}
            onChange={(value) => setAddress2(value)}
            colSize={2}
          />
          <FormInput
            label="City"
            name="city"
            value={city}
            onChange={(value) => setCity(value)}
            colSize={3}
          />
          <FormInput
            label="State"
            name="state"
            value={state}
            onChange={(value) => setState(value)}
            colSize={3}
          />
          <FormInput
            label="Zip"
            name="zip"
            value={zip}
            onChange={(value) => setZip(value)}
            colSize={3}
          />
          <FormInput
            label="Phone"
            name="phone"
            value={phone}
            onChange={(value) => setPhone(value)}
            colSize={2}
          />
          <FormInput
            label="Website"
            name="website"
            value={website}
            onChange={(value) => setWebsite(value)}
            colSize={2}
          />
          <div className="col-12 text-end pt-3">
            <button
              className="btn btn-primary"
              onClick={saveListing}
              disabled={savingListing}
            >
              Save
            </button>
          </div>

          <h3 className="my-4">Description</h3>
          <FormInput
            type="textarea"
            label="Business Description"
            name="description"
            value={description}
            onChange={(value) => setDescription(value)}
          />
          <div className="col-12 text-end pt-3">
            <button
              className="btn btn-primary"
              onClick={saveListing}
              disabled={savingListing}
            >
              Save
            </button>
          </div>

          <h3 className="my-4">Social Media</h3>
          <FormInput
            label="Facebook"
            name="facebook"
            value={facebook}
            onChange={(value) => setFacebook(value)}
          />
          <FormInput
            label="Twitter"
            name="twitter"
            value={twitter}
            onChange={(value) => setTwitter(value)}
          />
          <FormInput
            label="Instagram"
            name="instagram"
            value={instagram}
            onChange={(value) => setInstagram(value)}
          />
          <FormInput
            label="Youtube"
            name="youtube"
            value={youtube}
            onChange={(value) => setYoutube(value)}
          />
          <div className="col-12 text-end pt-3">
            <button
              className="btn btn-primary"
              onClick={saveListing}
              disabled={savingListing}
            >
              Save
            </button>
          </div>

          <h3 className="my-4">Images</h3>
          <FormInputLocal
            type="image"
            label="Listing Image"
            name="image"
            value={image}
            onChange={(value) => setImage(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 2"
            name="image2"
            value={image2}
            onChange={(value) => setImage2(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 3"
            name="image3"
            value={image3}
            onChange={(value) => setImage3(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 4"
            name="image4"
            value={image4}
            onChange={(value) => setImage4(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 5"
            name="image5"
            value={image5}
            onChange={(value) => setImage5(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 6"
            name="image6"
            value={image6}
            onChange={(value) => setImage6(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 7"
            name="image7"
            value={image7}
            onChange={(value) => setImage7(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 8"
            name="image8"
            value={image8}
            onChange={(value) => setImage8(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 9"
            name="image9"
            value={image9}
            onChange={(value) => setImage9(value)}
          />
          <FormInputLocal
            type="image"
            label="Image 10"
            name="image10"
            value={image10}
            onChange={(value) => setImage10(value)}
          />

          <h3 className="my-4">Categories</h3>

          <div className="col-12 pt-3">
            <p>Select up to 4 categories that apply to your business.</p>
            {tags &&
              tags.map((tag) => {
                let checked = checkedTags.includes(tag.id);
                return (
                  <div className="form-check form-check-inline" key={tag.id}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={tag.id}
                      name={tag.slug}
                      value={tag.id}
                      checked={checked}
                      onChange={(e) => handleTagChange(e)}
                    />
                    <label className="form-check-label" htmlFor={tag.id}>
                      {tag.name}
                    </label>
                  </div>
                );
              })}
          </div>

          <div className="col-12 text-end pt-3">
            <button
              className="btn btn-primary"
              onClick={saveListing}
              disabled={savingListing}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Listing;
