import React, { useEffect, useState } from "react";
import { ApiURL } from "../config";
import { useLocation } from "react-router-dom";

const Content = () => {
  const [content, setContent] = useState({});
  const location = useLocation();
  const getContent = () => {
    fetch(`${ApiURL}/content`, {
      method: "POST",
      body: JSON.stringify({ path: location.pathname }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setContent(data.content);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getContent();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getContent();
  }, [location]);

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/13.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white">{content.title}</h1>
          </div>
          <div className="col-12">
            <div className="card mb-3">
              <div
                className="card-body p-5"
                dangerouslySetInnerHTML={{ __html: content.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Content;
