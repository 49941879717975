import React from "react";
import classnames from "classnames";
import ShowInputError from "./ShowInputError";

const FormInputText = (props) => {
  const {
    field,
    name,
    value,
    label,
    colSize,
    placeholder,
    type,
    helpText,
    error,
    onBlur,
    onChange,
    children,
    refProp,
  } = props;

  let divColSize;
  if (colSize === 0) divColSize = 0;
  if (colSize === 1) divColSize = 12;
  if (colSize === 2) divColSize = 6;
  if (colSize === 3) divColSize = 4;
  if (colSize === 4) divColSize = 8;

  return (
    <div className={`col-12 align-bottom col-sm-${divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": error,
            })}
          >
            {label}
          </label>
          <div className="col-sm-12">
            <div className="input-group">
              <input
                ref={refProp}
                id={name}
                name={name}
                type={type}
                value={value === null ? "" : value}
                className={classnames("form-control form-control-sm", {
                  "is-invalid": error,
                })}
                onBlur={onBlur}
                onChange={(e) => onChange(type, e, field)}
                aria-describedby={name + "Help"}
                placeholder={placeholder}
              />
              {children}
            </div>
          </div>
          {helpText && (
            <>
              <div className="col-4">&nbsp;</div>
              <small id={name + "Help"} className="col-8 form-text text-muted">
                {helpText}
              </small>
            </>
          )}
          {error && <ShowInputError error={error} />}
        </div>
      </div>
    </div>
  );
};

export default FormInputText;
