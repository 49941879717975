import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {websiteUrl} from "../../../config";

const CheckoutForm = (props) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const result = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: websiteUrl + "/content/signup/" + props.subscriptionId + "/complete",
			},
		});

		console.log("result", result);

		if (result.error) {
			// Show error to your customer (for example, payment details incomplete)
			console.log(result.error.message);
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.

		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			<button className="btn btn-success mt-5" disabled={!stripe}>Submit Payment</button>
		</form>
	)
};

export default CheckoutForm;
