import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiURL } from "../config";

const Directory = () => {
  const [locations, setLocations] = useState([
    {
      id: 1,
      name: "Pinellas County",
      slug: "pinellas",
      description: "Pinellas County, Florida",
      image: "/assets/img/backgrounds/clearwater-beach-467984_1920.jpg",
    },
  ]);

  const getLocations = () => {
    fetch(`${ApiURL}/locations`, {
      method: "POST",
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setLocations(data.locations);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getLocations();
  }, []);

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/bg-1.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white">Select a Location</h1>
          </div>
          <div className="col-12 d-flex">
            {locations &&
              locations.length > 0 &&
              locations.map((location) => {
                return (
                  <article
                    className="card overflow-hidden mx-2 my-5"
                    style={{ maxWidth: "250px" }}
                  >
                    <div className="card-body p-0">
                      <div className="hoverbox h-md-100">
                        <Link
                          className="text-decoration-none"
                          to={`/d/${location.slug}}`}
                          data-gallery="attachment-bg"
                        >
                          <img
                            className="h-100 w-100 object-fit-cover"
                            src={location.image}
                            alt={location.name}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 p-x1" style={{ minHeight: "150px" }}>
                      <h4 className="mt-3 mt-sm-0 fs-0 fs-lg-1">
                        <Link className="text-900" to={`/d/${location.slug}`}>
                          {location.name}
                        </Link>
                      </h4>

                      <Link
                        to={`/d/${location.slug}`}
                        className="btn btn-md btn-primary mt-2 fs--1"
                      >
                        <span className="fas fa-th-list"></span>
                        <span className="ms-1 d-none d-lg-inline">More</span>
                      </Link>
                    </div>
                  </article>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Directory;
