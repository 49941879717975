import React from "react";
import { sortByName } from "../../components/helpers/textHelper";

const ListingFilter = ({ selectedFilters, filterToggle }) => {
  selectedFilters.sort(sortByName);
  return (
    <div className="d-flex gap-2 flex-wrap my-3">
      {selectedFilters &&
        selectedFilters.map((filter, index) => {
          return (
            <span
              className="badge bg-300 text-600 py-0"
              key={`selectedFilter${index}`}
            >
              {filter.name}
              <button
                className="btn btn-link btn-sm p-0 text-600 ms-1"
                onClick={() => filterToggle(filter)}
              >
                <span className="fas fa-times fs--2"></span>
              </button>
            </span>
          );
        })}
    </div>
  );
};
export default ListingFilter;
