export const ApiURL = "https://www.pinellaschristianbusinesses.com/api";
export const languageText = "";
export const minPasswordLength = 8;
export const minPasswordStrength = 3;
export const auth = true;
export const token = "";
export const stripeKey = "pk_test_51NTZDtIQYNXu2wsSqQMubVNgzi2gzOQY5cCio57GHFhx7aEwh5Gp1ZwMMj7i9XFrlssD877YILCvVGqyX5Z1BiF600HM50Q38x";
export const googleMapsApiKey = "AIzaSyDpjl0QCqpjIByhRhsJ9E8Hx26DlkUHwc4";

export const paymentEnvironment = "sandbox";

export const websiteUrl = getUrl();
function getUrl() {
  if (paymentEnvironment === "sandbox") {
    return "http://localhost:3000";
  } else {
    return "https://www.pinellaschristianbusinesses.com";
  }
}

export const configFilters = [
  { name: "Churches", icon: "globe" },
  { name: "Religious Orgs", icon: "globe" },
  { name: "Bookstores", icon: "globe" },
  { name: "Gift Shops", icon: "globe" },
  { name: "Schools", icon: "globe" },
  { name: "Mental Health", icon: "globe" },
  { name: "Dental", icon: "globe" },
  { name: "Medical", icon: "globe" },
  { name: "Youth Orgs", icon: "globe" },
  { name: "Ministries", icon: "globe" },
  { name: "Restaurants", icon: "globe" },
  { name: "Fitness", icon: "globe" },
  { name: "Real Estate Services", icon: "globe" },
  { name: "Legal Services", icon: "globe" },
  { name: "Financial Services", icon: "globe" },
  { name: "Social Services", icon: "globe" },
  { name: "Nonprofits", icon: "globe" },
  { name: "Miscellaneous", icon: "globe" },
  { name: "Thrift Store", icon: "globe" },
];
