import React from "react";
import PricingInfo from "./PricingInfo";
import { Link } from "react-router-dom";
const Pricing = () => {
  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/4.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white text-center">Directory Pricing</h1>
          </div>

          <div className="col-12">
            <div className="row">
              <PricingInfo />
            </div>
          </div>
          <div className="col-12 text-center">
            <h5 className="mt-5 text-white">Looking for more options?</h5>
            <p className="fs-1 text-white">
              <Link to="/content/contact">Contact us</Link> for more information
              on our custom packages.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Pricing;
