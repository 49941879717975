import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/layouts/Loader";
import { AuthTokenContext } from "../../App";
import { checkAuth } from "../../components/helpers/authHelper";
import Listing from "./Listing";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const AuthToken = useContext(AuthTokenContext);

  const checkUserAuth = async () => {
    setLoading(true);
    let auth = await checkAuth(AuthToken.authToken);
    setLoading(false);
    if (!auth) {
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    if (AuthToken.authToken) {
      checkUserAuth();
    } else {
      navigate("/auth/login");
    }
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body">
              <Listing />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
