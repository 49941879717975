import React, { useContext, useEffect, useState } from "react";
import Loading from "../../components/layouts/Loader";
import { ApiURL, auth, token } from "../../config";
import { useNavigate } from "react-router-dom";
import PricingInfo from "../PricingInfo";
import { AuthTokenContext } from "../../App";
import { checkAuth } from "../../components/helpers/authHelper";

const Subscription = () => {
  const [loading, setLoading] = useState(true);
  const [subscriptionInfo, setSubscriptionInfo] = useState([]);
  const navigate = useNavigate();
  const AuthToken = useContext(AuthTokenContext);

  const checkUserAuth = async () => {
    setLoading(true);
    let auth = await checkAuth(AuthToken.authToken);
    setLoading(false);
    if (!auth) {
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    if (AuthToken.authToken) {
      checkUserAuth();
    } else {
      navigate("/auth/login");
    }
  }, []);

  useEffect(() => {
    // should re-render
  }, [subscriptionInfo]);

  const processUpgrade = (
    subscriptionLevel,
    numberOfClients,
    amountOfDiskSpace
  ) => {
    console.log(
      "processing upgrade",
      subscriptionLevel,
      numberOfClients,
      amountOfDiskSpace
    );

    // @todo - insert popup modal to confirm upgrade
    let data = {
      subscriptionLevel,
      numberOfClients,
      amountOfDiskSpace,
    };
    saveUpgrade(data);
  };

  const saveUpgrade = (data) => {
    setLoading(true);
    fetch(`${ApiURL}/upgradeSubscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken.authToken,
      },
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.status === true) {
          setSubscriptionInfo(res.subscriptionInfo);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="container pb-5">
      <div className="row">
        <div className="col-12">
          <h1 className="text-white">Subscription</h1>
          <div className="container p-0 m-0">
            <div className="row g-1 p-0">
              <PricingInfo
                subscriptionInfo={subscriptionInfo}
                processUpgrade={processUpgrade}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscription;
