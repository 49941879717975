import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const DocumentBreadCrumb = (props) => {
  const { pathname } = useLocation();
  const { locationName, listingName } = useParams();

  const findLocationName = (name) => {
    name = name.replace(/-/g, " ");
    name = name.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return name;
  };

  useEffect(() => {}, [props]);
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb p-2 rounded">
        {pathname === "/d" && <BreadCrumbItem name={"Businesses"} />}
        {pathname !== "/d" && (
          <BreadCrumbItem link={`/d`} name={findLocationName("Directory")} />
        )}
        {locationName && !listingName && (
          <BreadCrumbItem name={findLocationName(locationName)} />
        )}
        {locationName && listingName && (
          <BreadCrumbItem
            link={`/d/${locationName}`}
            name={findLocationName(locationName)}
          />
        )}
        {listingName && <BreadCrumbItem name={findLocationName(listingName)} />}
      </ol>
    </nav>
  );
};

const BreadCrumbItem = ({ name, link = "" }) => {
  if (link) {
    return (
      <li className="breadcrumb-item btn-link cursorClick">
        <Link to={link}>{name}</Link>
      </li>
    );
  } else {
    return <li className="breadcrumb-item">{name}</li>;
  }
};
export default DocumentBreadCrumb;
