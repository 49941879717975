import React from "react";
import ListingCategories from "./ListingCategories";
import ListingFilter from "./ListingFilter";

const ListingNav = ({
  selectedFilters,
  filterToggle,
  resetFilters,
  filters,
}) => {
  return (
    <aside
      className="scrollbar-overlay font-sans-serif p-4 p-xl-3 ps-xl-0 offcanvas offcanvas-start offcanvas-filter-sidebar overflow-auto h-100"
      tabIndex="-1"
      id="filterOffcanvas"
      aria-labelledby="filterOffcanvasLabel"
    >
      <div className="d-flex flex-between-center">
        <div className="d-flex gap-2 flex-xl-grow-1 align-items-center justify-content-xl-between">
          <h5
            className="mb-0 text-700 d-flex align-items-center"
            id="filterOffcanvasLabel"
          >
            <span className="fas fa-filter fs--1 me-1"></span>
            <span>Filter</span>
          </h5>
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={resetFilters}
          >
            Reset
          </button>
        </div>
        <button
          className="btn-close text-reset d-xl-none shadow-none"
          type="button"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <ListingFilter
        filterToggle={filterToggle}
        selectedFilters={selectedFilters}
      />
      <ListingCategories
        filters={filters}
        filterToggle={filterToggle}
        selectedFilters={selectedFilters}
      />
    </aside>
  );
};
export default ListingNav;
