import React, { useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { FormInput } from "cmation-formsbuilder";
import { useNavigate } from "react-router-dom";

function SignUpWorkspace({ processSubscription, data }) {
  const [error, setError] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [listingPhone, setListingPhone] = useState(data.listingPhone || "");
  const [listingAddress1, setListingAddress1] = useState(
    data.listingAddress1 || ""
  );
  const [listingAddress2, setListingAddress2] = useState(
    data.listingAddress2 || ""
  );
  const [listingCity, setListingCity] = useState(data.listingCity || "");
  const [listingState, setListingState] = useState(data.listingState || "");
  const [listingZip, setListingZip] = useState(data.listingZip || "");
  const [firstName, setFirstName] = useState(data.firstName || "");
  const [lastName, setLastName] = useState(data.lastName || "");

  const checkForErrors = () => {
    let errorTMP = error;
    let hasError = false;
    if (!firstName) {
      errorTMP["firstName"] = "Please enter your first name";
      setError(errorTMP);
      return true;
    }
    if (!lastName) {
        errorTMP["lastName"] = "Please enter your last name";
        setError(errorTMP);
        return true;
    }
    if (!companyName) {
      errorTMP["companyName"] = "Please enter your company name";
      setError(errorTMP);
      return true;
    }
    if (!listingPhone) {
      errorTMP["listingPhone"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["listingPhone"];
    }
    if (!listingAddress1) {
      errorTMP["listingAddress1"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["listingAddress1"];
    }
    if (!listingCity) {
      errorTMP["listingCity"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["listingCity"];
    }
    if (!listingState) {
      errorTMP["listingState"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["listingState"];
    }
    if (!listingZip) {
      errorTMP["listingZip"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["listingZip"];
    }

    if (hasError) {
      setError(errorTMP);
      forceUpdate();
      return true;
    }
    return false;
  };

  const continueSignup = () => {
    forceUpdate();
    console.log("continueSignup", firstName, lastName, companyName, listingPhone, listingAddress1, listingAddress2, listingCity, listingState, listingZip);
    if (!checkForErrors()) {
      processSubscription(1, {
        firstName:firstName,
        lastName:lastName,
        companyName:companyName,
        listingPhone: listingPhone,
        listingAddress1: listingAddress1,
        listingAddress2: listingAddress2,
        listingCity: listingCity,
        listingState: listingState,
        listingZip: listingZip,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Pinellas Christian Businesses | Listing Information</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>

      <div className="container">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row">
            <div className="col-12">
              <h2 className="py-4 text-center">
                Enter your Business Listing Information
              </h2>
              <p>
                Here you will enter the information you want to appear on the
                business part of your listing. This information will be visible
                to the public.
              </p>
              <p>
                Once you complete your signup you will be able to finish
                entering your business listing information and upload photos as
                well as choosing categories you want your listing to appear in.
              </p>
            </div>
          </div>
          <div className="row">
            <FormInput
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                label="First Name"
                placeholder="First Name"
                colSize={2}
                onChange={(value) => setFirstName(value)}
                error={error["firstName"]}
            />
            <FormInput
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                label="Last Name"
                placeholder="Last Name"
                colSize={2}
                onChange={(value) => setLastName(value)}
                error={error["lastName"]}
            />
            <FormInput
              type="text"
              id="companyName"
              name="companyName"
              value={companyName}
              label="Company Name"
              placeholder="Company Name"
              colSize={2}
              onChange={(value) => setCompanyName(value)}
              error={error["companyName"]}
            />
            <FormInput
              type="text"
              name="listingPhone"
              label="Phone"
              colSize={2}
              value={listingPhone}
              placeholder="Phone"
              onChange={(value) => setListingPhone(value)}
              error={error["listingPhone"]}
            />
            <FormInput
              type="text"
              name="listingAddress1"
              label="Address"
              colSize={2}
              value={listingAddress1}
              placeholder="Address"
              onChange={(value) => setListingAddress1(value)}
              error={error["listingAddress1"]}
            />
            <FormInput
              type="text"
              name="listingAddress2"
              label="Address 2"
              colSize={2}
              value={listingAddress2}
              placeholder="Address continued"
              onChange={(value) => setListingAddress2(value)}
              error={error["listingAddress2"]}
            />
            <FormInput
              type="text"
              name="listingCity"
              label="City"
              colSize={3}
              value={listingCity}
              placeholder="City"
              onChange={(value) => setListingCity(value)}
              error={error["listingCity"]}
            />
            <FormInput
              type="state"
              name="listingState"
              label="State"
              colSize={3}
              value={listingState}
              placeholder="State"
              onChange={(value) => setListingState(value)}
              error={error["listingState"]}
            />
            <FormInput
              type="text"
              name="listingZip"
              label="Zip"
              colSize={3}
              value={listingZip}
              placeholder="Zip"
              onChange={(value) => setListingZip(value)}
              error={error["listingZip"]}
            />
          </div>

          <div className="row py-3 justify-content-center">
            <div className="col-6">
              <button
                type={"button"}
                className="btn btn-sm btn-secondary"
                onClick={() => navigate("/product/pricing")}
              >
                Cancel
              </button>
            </div>
            <div className="col-6 text-end">
              <button
                type={"submit"}
                className="btn btn-sm btn-primary"
                onClick={continueSignup}
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default SignUpWorkspace;
