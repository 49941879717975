import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../../components/form/FormInput";
import { ApiURL } from "../../config";

const LostPassword = () => {
  const [errors, setErrors] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showUserScreen, setShowUserScreen] = useState(true);
  const [showCodeScreen, setShowCodeScreen] = useState(false);
  const [showResetScreen, setShowResetScreen] = useState(false);

  const sendResetEmail = (e) => {
    e.preventDefault();
    sendForm("forgotPass", { email }, 1);
  };

  const onSubmitVerification = (e) => {
    e.preventDefault();
    if (!verificationCode) {
      let errorsCode = [];
      errorsCode.verificationCode = "This field is required!";
      setErrors(errorsCode);
    } else {
      sendForm("checkVerificationCode", { email, verificationCode }, 2);
    }
  };

  const onSubmitPassword = (e) => {
    e.preventDefault();
    let errorsCode = [];
    let error = false;
    if (!password) {
      error = true;
      errorsCode.password = "This field is required!";
    }
    if (!passwordConfirm) {
      error = true;
      errorsCode.confirmPassword = "This field is required!";
    }
    setErrors(errorsCode);
    if (!error) {
      sendForm(
        "saveForgotPass",
        { email, verificationCode, password, passwordConfirm },
        3
      );
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  const sendForm = (path, data, step) => {
    setButtonLoading(true);
    fetch(`${ApiURL}/auth/${path}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setButtonLoading(false);

        if (res.status === true) {
          setErrors({});
          setSuccessMessage(res.message);
          setErrorMessage("");
          if (step === 1) {
            setShowUserScreen(false);
            setShowCodeScreen(true);
            setShowResetScreen(false);
          }
          if (step === 2) {
            setShowUserScreen(false);
            setShowCodeScreen(false);
            setShowResetScreen(true);
          }
          if (step === 3) {
            setShowUserScreen(true);
            setShowCodeScreen(false);
            setShowResetScreen(false);
            navigate("/auth/login");
          }
        } else {
          setErrors(res.errors);
          setSuccessMessage("");
          setErrorMessage(res.message);
        }
      })
      .catch((err) => {
        setButtonLoading(false);
        console.log("authLogin Error", err);
      });
  };

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="dark">
      <div className="container-fluid">
        <div className="row min-vh-100 bg-100">
          <div className="col-6 d-none d-lg-block position-relative">
            <div
              className="bg-holder overlay"
              style={{
                backgroundImage: "url(/assets/img/generic/17.jpg)",
                backgroundPosition: "50% 76%",
              }}
            ></div>
          </div>
          <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
            <div className="row justify-content-center g-0">
              <div className="col-lg-9 col-xl-8 col-xxl-6">
                <div className="card">
                  <div className="card-header bg-circle-shape bg-shape text-center p-2">
                    <Link
                      className="font-sans-serif fw-bolder fs-4 z-1 position-relative link-light"
                      to="/"
                      data-bs-theme="light"
                    >
                      Pinellas Christian Businesses
                    </Link>
                  </div>
                  <div className="card-body p-4">
                    <div className="text-center">
                      {errorMessage && (
                        <div className="alert alert-danger">
                          <div className="alert-message">{errorMessage}</div>
                        </div>
                      )}
                      {successMessage && (
                        <div className="alert alert-success">
                          <div className="alert-message">{successMessage}</div>
                        </div>
                      )}

                      {showUserScreen && (
                        <>
                          <h4 className="mb-0"> Forgot your password?</h4>
                          <small>
                            Enter your email and we'll send you a reset link.
                          </small>
                          <form className="mb-3 mt-4">
                            <FormInput
                              id="email"
                              name="email"
                              label="Email Address"
                              placeholder="Email Address"
                              value={email}
                              onChange={(type, e, field) =>
                                setEmail(e.target.value)
                              }
                              error={errors?.email ?? null}
                            />
                            <div className="mb-3"></div>

                            {buttonLoading ? (
                              <div
                                className="spinner-border text-warning"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary d-block w-100 mt-3"
                                type="submit"
                                name="submit"
                                onClick={sendResetEmail}
                              >
                                Reset Password
                              </button>
                            )}
                          </form>
                        </>
                      )}

                      {showCodeScreen && (
                        <>
                          <h4 className="mb-0">Reset password</h4>
                          <small>
                            Check your email and enter the code from your email
                            below.
                          </small>
                          <form onSubmit={onFormSubmit}>
                            <div className="form-group row my-5">
                              <FormInput
                                colSize={1}
                                type="text"
                                name="verificationCode"
                                label="Verification Code"
                                value={verificationCode}
                                onChange={(type, e, field) =>
                                  setVerificationCode(e.target.value)
                                }
                                error={errors?.verificationCode ?? null}
                              />
                            </div>
                            <div className="text-center mt-3">
                              <button
                                className="btn btn-primary"
                                onClick={onSubmitVerification}
                              >
                                {" "}
                                Submit
                              </button>
                              <br />
                              <br />
                              <Link to="/auth/sign-in">
                                <button className="btn btn-secondary">
                                  Back
                                </button>
                              </Link>
                            </div>
                          </form>
                        </>
                      )}

                      {showResetScreen && (
                        <>
                          <div className="text-center">
                            <h1 className="h2">Reset password</h1>
                            <p className="lead">Enter your new password</p>
                          </div>
                          <form onSubmit={onFormSubmit}>
                            <div className="form-group">
                              <FormInput
                                type="password"
                                name="password"
                                label="Password"
                                value={password}
                                onChange={(type, e, field) =>
                                  setPassword(e.target.value)
                                }
                                hideMeter={true}
                                error={errors?.password ?? null}
                              />
                            </div>
                            <div className="form-group">
                              <FormInput
                                type="password"
                                name="passwordConfirm"
                                label="Confirm Password"
                                value={passwordConfirm}
                                onChange={(type, e, field) =>
                                  setPasswordConfirm(e.target.value)
                                }
                                hideMeter={true}
                                error={errors?.passwordConfirm ?? null}
                              />
                            </div>

                            {buttonLoading ? (
                              <div
                                className="spinner-border text-warning"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary btn-block"
                                onClick={onSubmitPassword}
                              >
                                <i className="fas fa-unlock-alt" /> SUBMIT
                              </button>
                            )}
                          </form>
                        </>
                      )}

                      <Link className="fs--1 text-600" to="/content/contact">
                        I can't recover my account using this page
                        <span className="d-inline-block ms-1">&rarr;</span>
                      </Link>
                      <br />
                      <Link className="fs--1 text-600" to="/auth/login">
                        <span className="d-inline-block ms-1">&larr;</span>
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LostPassword;
