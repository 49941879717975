import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ApiURL } from "../config";
const FAQs = () => {
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);

  // const faqs = [
  //   {
  //     question: "What is online document security?",
  //     answer:
  //       "Online document security refers to the process of protecting digital documents from unauthorized access, use, or disclosure. It involves the use of encryption, access controls, and other security measures to keep sensitive information safe and secure.",
  //   },
  //   {
  //     question: "What services do you offer?",
  //     answer:
  //       "We offer a range of online document security solutions, including secure document storage and sharing, digital signature solutions, and document verification services. Our platform is user-friendly and intuitive, making it easy for anyone to get started with secure document management.",
  //   },
  //   {
  //     question: "Is your platform secure?",
  //     answer:
  //       "Yes, our platform is highly secure. We use state-of-the-art encryption and security measures to protect user data both at rest and in transit. We also employ a team of dedicated security professionals to ensure that our platform remains secure and up-to-date.",
  //   },
  //   {
  //     question: "Can I sign up for a subscription online?",
  //     answer:
  //       "Yes, we offer online subscriptions for all of our services. Our subscription plans are flexible and customizable, allowing you to choose the plan that best fits your needs and budget.",
  //   },
  //   {
  //     question:
  //       "What types of documents can I store and share on your platform?",
  //     answer:
  //       "Our platform is designed to support a wide range of document types, including PDFs, Word documents, Excel spreadsheets, and more. You can upload and share any type of document that you need to keep secure.",
  //   },
  //   {
  //     question: "Can I sign documents electronically using your platform?",
  //     answer:
  //       "Yes, we offer digital signature solutions that allow you to sign documents electronically, eliminating the need for paper-based signatures and streamlining document workflows.",
  //   },
  //   {
  //     question: "How do I verify the authenticity of signed documents?",
  //     answer:
  //       "Our verification services use advanced algorithms to ensure the authenticity of signed documents, providing peace of mind to both signers and recipients. You can easily verify the authenticity of a signed document by uploading it to our platform.",
  //   },
  //   {
  //     question: "How much does it cost to use your platform?",
  //     answer:
  //       "We offer flexible subscription plans to meet the needs and budget of our customers. Our pricing is competitive and transparent, and we do not charge any hidden fees.",
  //   },
  //   {
  //     question: "Can I access my documents from anywhere?",
  //     answer:
  //       "Yes, our platform is accessible from anywhere with an internet connection. You can access your documents from your desktop, laptop, tablet, or smartphone, making it easy to manage your documents on the go.",
  //   },
  //   {
  //     question: "Do you offer customer support?",
  //     answer:
  //       "Yes, we offer exceptional customer support to ensure that our customers have the assistance they need to use our platform effectively. Our support team is available via phone, email, and live chat to answer any questions you may have.",
  //   },
  // ];

  const getFaqs = () => {
    fetch(`${ApiURL}/faqs`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setLoading(false);
          setFaqs(data.faqs);
        }
      });
  };

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/5.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <h1 className="text-white text-center">
            Frequently Answered Questions
          </h1>
          <div className="col-12 mb-4">
            <Accordion>
              {faqs.map((faq, index) => {
                return (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    />
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FAQs;
