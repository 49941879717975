import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ApiURL} from "../../../config";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SignUpCompleting({ processSubscription }) {
  const params = useQuery();
  const payment_intent = params.get("payment_intent");
  const payment_intent_client_secret = params.get("payment_intent_client_secret");
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const loadPaymentIntent = () => {
    fetch(ApiURL + "/stripe/checkIntent", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
            payment_intent: payment_intent,
            payment_intent_client_secret: payment_intent_client_secret,
        },
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      console.log("loadPaymentIntent json: ", json);
      if (!json.status) {
        setError(json.message);
      } else {
        navigate('/auth/login');
      }
    })
    .catch((error) => {
      console.log("error", error);
      setError(error);
    });
  };

  useEffect(() => {
    loadPaymentIntent();
  }, [payment_intent, payment_intent_client_secret]);

  return (
    <>
      <Helmet>
        <title>Pinellas Christian Businesses | Sign-up Complete</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container subscriptionHeader">
        <div className="row p-5">
          <div className="col-12 p-5 text-white">
            <h1 className="text-center py-3">Creating your account</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="text-center py-5">
              If you account is created successfully you will be redirected to
              the login page.
            </p>
            {error && (<>
              <div className="alert alert-danger">{error}</div>
            </>)}
          </div>
        </div>
        <div className="row justify-content-center mb-5">
          <div className="col-6 text-center">
            <button
              className="btn btn-sm btn-primary"
              onClick={loadPaymentIntent}
            >
              Re-Try
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpCompleting;
