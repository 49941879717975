import React, { useContext } from "react";
import classnames from "classnames";
import ShowInputError from "./ShowInputError";
import Loader from "../layouts/Loader";
import { ApiURL } from "../../config";
import { AuthTokenContext } from "../../App";

const FormInputImage = (props) => {
  const AuthToken = useContext(AuthTokenContext);
  const [uploading, setUploading] = React.useState(false);

  let divColSize;
  if (props.colSize === 0) divColSize = 0;
  if (props.colSize === 1) divColSize = 12;
  if (props.colSize === 2) divColSize = 6;
  if (props.colSize === 3) divColSize = 4;
  if (props.colSize === 4) divColSize = 8;

  const onUpload = (e) => {
    setUploading(true);
    const files = Array.from(e.target.files);
    const formData = new FormData();
    formData.append(props.name, files[0]);

    fetch(`${ApiURL}/account/image-upload`, {
      method: "POST",
      headers: { Authorization: AuthToken.authToken },
      body: formData,
    })
      .then((res) => res.json())
      .then((images) => {
        setUploading(false);
        props.onChange(images.newFileName);
      });
  };

  const onDeleteFile = () => {
    props.onChange(null);
  };

  return (
    <div className={`col-12 align-bottom col-sm-${divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom py-2">
          {props.value && (
            <>
              <label className="col-4" htmlFor={props.name}>
                {props.label}
              </label>
              <div className="col-4 text-center">
                <img
                  src={props.value}
                  border="2"
                  width="100"
                  className="img-fluid mb-2"
                  alt=""
                />
                <br />
                <a
                  href={props.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-success"
                >
                  View Image
                </a>{" "}
                <button className="btn btn-danger" onClick={onDeleteFile}>
                  Delete Image
                </button>
              </div>
              <div className="col-4">
                {!uploading && (
                  <input
                    id={props.name}
                    name={props.name}
                    type="file"
                    className={classnames({ "is-invalid": props.error })}
                    onChange={onUpload}
                    aria-describedby={props.name + "Help"}
                    placeholder={props.placeholder}
                  />
                )}
                {uploading && (
                  <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
            </>
          )}

          {!props.value && (
            <>
              <label className="col-4" htmlFor={props.name}>
                {props.label}
              </label>
              <div className="col-8">
                {!uploading && (
                  <input
                    id={props.name}
                    name={props.name}
                    type="file"
                    className={classnames({ "is-invalid": props.error })}
                    onChange={onUpload}
                    aria-describedby={props.name + "Help"}
                    placeholder={props.placeholder}
                  />
                )}
                {uploading && (
                  <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
            </>
          )}

          {props.helpText && (
            <small
              id={props.name + "Help"}
              className="col-12 form-text text-muted"
            >
              {props.helpText}
            </small>
          )}
          {props.error && <ShowInputError error={props.error} />}
        </div>
      </div>
    </div>
  );
};

export default FormInputImage;
