import React, { useEffect, useState } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import SignUpPayment from "./SignUpPayment";
import SignUpCompleting from "./SignUpCompleting";
import SignUpBillingInformation from "./SignUpBillingInformation";
import SignUpListing from "./SignUpListing";
import { ApiURL, paymentEnvironment } from "../../../config";

const SignUp = (props) => {
  const { subscriptionType, page } = useParams();

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState({});
  const [paymentError, setPaymentError] = useState("");

  const [price, setPrice] = useState(0);
  const [discountCode, setDiscountCode] = useState("");
  const [scrollToTopStep, setScrollToTopStep] = useState(0);

  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  const setPage = () => {
    if (page === 'listing') {
        setCurrentStep(1);
    }
    if (page === 'billing') {
        setCurrentStep(2);
    }
    if (page === 'payment') {
        setCurrentStep(3);
    }
    if (page === 'complete') {
        setCurrentStep(4);
    }
  };

  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const calculatePrice = () => {
    let subscriptionCost = parseInt(
      subscriptionTypes.filter((sub) => sub.id === subscriptionType)[0]?.price
    );

    let subscriptionInfo = subscriptionTypes.filter(
      (sub) => sub.id === subscriptionType
    )[0];

    let paymentButtonKey = paymentEnvironment === 'sandbox' ? subscriptionInfo.devPaymentButtonKey : subscriptionInfo.paymentButtonKey;
    let price = subscriptionCost;
    setPrice(price * 100);
    setData({
      paymentButtonKey: paymentButtonKey,
      subscriptionId: subscriptionType,
      subscriptionCost: subscriptionCost,
      subscriptionType: subscriptionType,
      amount: price * 100,
    });
    setPage();
  };

  useEffect(() => {
    if (subscriptionTypes.length > 0) {
      calculatePrice();
    }
  }, [subscriptionTypes]);

  const loadSubscriptionTypes = () => {
    fetch(ApiURL + "/subscription-types", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!json.status) {
          setLoading(false);
        } else {
          setLoading(false);
          setSubscriptionTypes(json.subscriptionTypes);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });

    // setSubscriptionTypes
  };

  useEffect(() => {
    if (subscriptionTypes.length === 0 && loading === true) {
      loadSubscriptionTypes();
    }
  }, [subscriptionTypes, loading]);

  useEffect(() => {
    if (currentStep !== scrollToTopStep) {
      window.scrollTo(0, 0);
      setScrollToTopStep(scrollToTopStep);
    }
  }, [currentStep]);

  const processSubscription = (step, value) => {
    if (step === 1) {
      setData({
        ...data,
        companyName: value.companyName,
        firstName: value.firstName,
        lastName: value.lastName,
        listingPhone: value.listingPhone,
        listingAddress1: value.listingAddress1,
        listingAddress2: value.listingAddress2,
        listingCity: value.listingCity,
        listingState: value.listingState,
        listingZip: value.listingZip,
      });
      setCurrentStep(2);
    }

    if (step === 2) {
      setCurrentStep(3);
      setData({
        ...data,
        username: value.username,
        password: value.password,
        confirmPassword: value.confirmPassword,
      });
    }
    if (step === 3) {
      setCurrentStep(4);
      setDiscountCode(value.discountCode);
      let tmpData = {
        ...data,
        nonce: value.nonce,
        discountCode: value.discountCode,
      };
      setData(tmpData);
      completeSubscription(tmpData);
    }

    if (step === 4) {
      completeSubscription(data);
    }
  };

  useEffect(() => {}, [data]);

  const cancelSubscription = () => {
    setCurrentStep(1);
    setData({});
  };

  const completeSubscription = (tmpData) => {
    fetch(`${ApiURL}/subscription/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: tmpData,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          cancelSubscription();
          navigate("/auth/login");
        } else {
          // if fail show page with failure
          setCurrentStep(res.error);
          setError(res.errorField);
          if (res.Exception) {
            setPaymentError(res.Exception);
          }
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/13.jpg)",
          backgroundPosition: "center bottom",
        }}
      />

      <div className="container mb-4">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0 m-sm-0 p-sm-0">
          <div className="col-12 text-white text-center">
            <h1 className="text-center text-white">Subscription Information</h1>
            <p>
              To subscribe to our services enter the subscription information
              below.
            </p>
          </div>
          <div className="col-lg-8 col-12 m-sm-0 p-sm-0">
            <div className="card theme-wizard mb-5 m-sm-0 p-sm-0" id="wizard">
              <div className="card-header bg-light m-sm-0 p-sm-0">
                <ul className="nav justify-content-between nav-wizard pt-4">
                  <NavLink
                    link={{
                      title: "Listing",
                      active: currentStep === 1,
                      icon: "lock",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Billing",
                      active: currentStep === 2,
                      icon: "file-invoice-dollar",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Payment",
                      active: currentStep === 3,
                      icon: "dollar-sign",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Done",
                      active: currentStep === 4,
                      icon: "thumbs-up",
                    }}
                  />
                </ul>
              </div>
              <div className="card-body m-sm-0 p-sm-0">
                {currentStep === 1 && (
                  <SignUpListing
                    processSubscription={processSubscription}
                    data={data}
                    screenError={error}
                  />
                )}
                {currentStep === 2 && (
                  <SignUpBillingInformation
                    processSubscription={processSubscription}
                    data={data}
                    backStep={backStep}
                    screenError={error}
                  />
                )}
                {currentStep === 3 && (
                  <>
                    {paymentError && (
                      <div className="alert alert-danger">{paymentError}</div>
                    )}
                    <SignUpPayment
                      processSubscription={processSubscription}
                      data={data}
                      price={price}
                      backStep={backStep}
                      screenError={error}
                      discountCode={discountCode}
                    />
                  </>
                )}
                {currentStep === 4 && (
                  <SignUpCompleting processSubscription={processSubscription} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignUp;

const NavLink = ({ link }) => {
  return (
    <li className="nav-item">
      <div
        className={`nav-link ${link.active ? "active" : ""} fw-semi-bold`}
        data-bs-toggle="tab"
        data-wizard-step="data-wizard-step"
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <span className={`fas fa-${link.icon ? link.icon : ""}`}></span>
          </span>
        </span>
        <span
          className={`d-none ${
            link.active ? "text-dark" : ""
          } d-md-block mt-1 fs--1`}
        >
          {link.title}
        </span>
      </div>
    </li>
  );
};
