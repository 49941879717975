import React, { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { FormInput } from "cmation-formsbuilder";
import {
  ApiURL,
  minPasswordLength,
  minPasswordStrength,
} from "../../../config";
import zxcvbn from "zxcvbn";

function SignUpUserInformation({
  processSubscription,
  backStep,
  data,
  screenError,
}) {
  const [error, setError] = useState(screenError || {});

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [username, setUsername] = useState(data.username || "");
  const [password, setPassword] = useState(data.password || "");
  const [confirmPassword, setConfirmPassword] = useState(
    data.confirmPassword || ""
  );

  useEffect(() => {
    forceUpdate();
  }, [username, password, confirmPassword]);

  useEffect(() => {
    forceUpdate();
  }, [error]);

  const checkForErrors = () => {
    let errorTMP = error;
    let hasError = false;

    delete errorTMP["password"];
    delete errorTMP["confirmPassword"];

    if (password.length < minPasswordLength) {
      errorTMP["password"] = "Your password is too short!";
      hasError = true;
    } else if (password !== confirmPassword) {
      errorTMP["confirmPassword"] = "Your passwords do not match!";
      errorTMP["password"] = "Your passwords do not match!";
      hasError = true;
    } else {
      if (zxcvbn(password).score < minPasswordStrength) {
        errorTMP["password"] = "Your password is too weak! Make sure you have a mix of upper and lower case letters, numbers, and symbols.";
        hasError = true;
      }
    }

    if (hasError) {
      setError(errorTMP);
      forceUpdate();
      return true;
    }

    return false;
  };

  const checkUsernameErrors = () => {
    let errorTMP = error;

    if (!username) {
      errorTMP["username"] = "Field is required!";
      setError(errorTMP);
      forceUpdate();
      return true;
    }
  };

  const checkUsername = () => {
    if (!checkUsernameErrors()) {
      fetch(`${ApiURL}/subscription/checkUsername`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === false) {
            let errorTMP = error;
            errorTMP["username"] = res.message;
            setError(errorTMP);
          } else {
            let errorTMP = error;
            delete errorTMP["username"];
            setError(errorTMP);
          }
          forceUpdate();
        })
        .catch(() => {});
    }
  };

  const checkPassword = () => {
    let newError = error;
    let hasError = false;
    delete newError["password"];
    delete newError["confirmPassword"];

    if (password.length < minPasswordLength) {
      newError["password"] = "Your password is too short!";
      hasError = true;
    } else if (password !== confirmPassword) {
      newError["confirmPassword"] = "Your passwords do not match!";
      newError["password"] = "Your passwords do not match!";
      hasError = true;
    } else {
      if (zxcvbn(password).score < minPasswordStrength) {
        newError["password"] = "Your password is too weak! Make sure you have a mix of upper and lower case letters, numbers, and symbols.";
        hasError = true;
      }
    }
    setError(newError);
    forceUpdate();
    return hasError;
  };

  const continueSignup = () => {
    if (!checkForErrors()) {
      processSubscription(2, {
        username: username,
        password: password,
        confirmPassword: confirmPassword
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Pinellas Christian Businesses | Billing Information</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="container">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-12">
                    <h2 className="mt-4">Login Information</h2>
                    <p>
                      This will be the username and password you use to manage
                      your listings.
                    </p>
                  </div>
                  <FormInput
                    type="text"
                    name="username"
                    label="Email"
                    colSize={1}
                    value={username}
                    placeholder="Email"
                    onChange={(value) => setUsername(value)}
                    onBlur={checkUsername}
                    error={error["username"]}
                  />
                  <FormInput
                    type="password"
                    name="password"
                    label="Password"
                    colSize={2}
                    value={password}
                    placeholder="Password"
                    onChange={(value) => setPassword(value)}
                    onBlur={checkPassword}
                    error={error["password"]}
                  />
                  <FormInput
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    colSize={2}
                    value={confirmPassword}
                    placeholder="Confirm Password"
                    onChange={(value) => setConfirmPassword(value)}
                    onBlur={checkPassword}
                    error={error["confirmPassword"]}
                  />
                </div>

                <div className="row py-3 justify-content-center">
                  <div className="col-6">
                    <button
                      type={"button"}
                      className="btn btn-sm btn-secondary"
                      onClick={backStep}
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-6 text-end">
                    <button
                      type={"submit"}
                      className="btn btn-sm btn-primary"
                      onClick={continueSignup}
                      disabled={Object.keys(error).length !== 0}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpUserInformation;
