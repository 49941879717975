import React from "react";

const ListingCategories = ({ selectedFilters, filterToggle, filters }) => {
  const [toggle, setToggle] = React.useState(true);
  return (
    <ul className="list-unstyled">
      <li className="border-bottom">
        <button
          className="btn w-100 nav-link text-start collapse-indicator-plus fs--2 fw-medium text-600 py-3"
          data-bs-toggle="collapse"
          onClick={() => setToggle(!toggle)}
          aria-controls="subject-collapse"
          aria-expanded="true"
        >
          Categories
        </button>

        <div
          className={`collapse ${toggle ? "show" : ""}`}
          id="subject-collapse"
        >
          <ul className="list-unstyled pt-3">
            {filters &&
              filters.map((filter, index) => {
                let name = filter.name;
                let icon = filter.icon;
                if (!icon) icon = "globe";
                return (
                  <li key={`filterCategory${index}`}>
                    <div className="form-check d-flex ps-0">
                      <label
                        className="form-check-label fs--1 flex-1 text-truncate"
                        htmlFor={`filter-subject-${name
                          .toLowerCase()
                          .replace(/ /g, "-")}`}
                      >
                        <span className={`fas fa-${icon} fs--1 me-3`}></span>
                        {name}
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name.toLowerCase().replace(/ /g, "-")}
                        id={`filter-subject-${name
                          .toLowerCase()
                          .replace(/ /g, "-")}`}
                        checked={selectedFilters.includes(filter)}
                        onChange={() => filterToggle(filter)}
                      />
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </li>
    </ul>
  );
};
export default ListingCategories;
