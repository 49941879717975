import React from "react";
import { Link } from "react-router-dom";

const Listing = ({ listing }) => {
  return (
    <article className="card mb-3 overflow-hidden">
      <div className="card-body p-0">
        <div className="row g-0">
          <div className="col-md-4 col-lg-3">
            <div className="hoverbox h-md-100">
              <Link
                className="text-decoration-none"
                to={`/d/pinellas/${listing.slug}/${listing.id}`}
                data-gallery="attachment-bg"
              >
                <img
                  className="h-100 w-100 object-fit-cover"
                  src={listing.image}
                  alt=""
                />
              </Link>
              <div className="hoverbox-content flex-center pe-none bg-holder overlay overlay-2">
                <img
                  className="z-1"
                  src="/assets/img/icons/spot-illustrations/location.svg"
                  width="60"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-9 p-x1">
            <div className="row g-0 h-100">
              <div className="col-lg-8 col-xxl-9 d-flex flex-column pe-x1">
                <div className="d-flex gap-2 flex-wrap mb-3">
                  {listing.filters &&
                    listing.filters?.map((filter, index) => {
                      return (
                        <span
                          className="badge rounded-pill badge-subtle-secondary"
                          key={`filterListing${index}`}
                        >
                          <span>{filter}</span>
                        </span>
                      );
                    })}
                </div>
                <h4 className="mt-3 mt-sm-0 fs-0 fs-lg-1">
                  <Link
                    className="text-900"
                    to={`/d/pinellas/${listing.slug}/${listing.id}`}
                  >
                    {listing.name}
                  </Link>
                </h4>
                <span>{listing.address}</span>
                <span>{listing.phone}</span>
                <p className="fs--1 mt-2 d-none d-lg-block">
                  {listing.description}
                </p>
              </div>
              <div className="col-lg-4 col-xxl-3 mt-4 mt-lg-0">
                <div className="h-100 rounded border-lg border-1 d-flex flex-lg-column justify-content-between p-lg-3">
                  <div className="d-flex flex-lg-column gap-2 text-center">
                    <a
                      href={`https://www.google.com/maps/dir//${encodeURIComponent(
                        listing.name + " " + listing.address
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-md btn-outline-warning fs--1"
                    >
                      <span className="fas fa-map-marker-alt"></span>
                      <span className="ms-1 d-none d-lg-inline">
                        Get Directions
                      </span>
                    </a>
                    <Link
                      to={`/d/pinellas/${listing.slug}/${listing.id}`}
                      className="btn btn-md btn-primary fs--1"
                    >
                      <span className="fas fa-th-list"></span>
                      <span className="ms-1 d-none d-lg-inline">More</span>
                    </Link>
                    <div>
                      <span className="fs--1 text-600">
                        {listing?.facebook && (
                          <a
                            className="btn btn-link m-0 p-0"
                            href={listing.facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="img-fluid me-1"
                              src="/assets/img/logos/facebook.png"
                              alt=""
                              style={{
                                maxWidth: "30px",
                                maxHeight: "30px",
                              }}
                            />
                          </a>
                        )}
                        {listing.twitter && (
                          <a
                            className="btn btn-link m-0 p-0"
                            href={listing.twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="img-fluid me-1"
                              src="/assets/img/logos/twitter.png"
                              alt=""
                              style={{
                                maxWidth: "30px",
                                maxHeight: "30px",
                              }}
                            />
                          </a>
                        )}
                        {listing.youtube && (
                          <a
                            className="btn btn-link m-0 p-0"
                            href={listing.youtube}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="img-fluid me-1"
                              src="/assets/img/logos/youtube.png"
                              alt=""
                              style={{
                                maxWidth: "30px",
                                maxHeight: "30px",
                              }}
                            />
                          </a>
                        )}
                        {listing.instagram && (
                          <a
                            className="btn btn-link m-0 p-0"
                            href={listing.instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="img-fluid me-1"
                              src="/assets/img/icons/instagram.png"
                              alt=""
                              style={{
                                maxWidth: "30px",
                                maxHeight: "30px",
                              }}
                            />
                          </a>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
export default Listing;
