import { googleMapsApiKey } from "../../config";
import React, { useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";

const containerStyle = {
  width: "100%",
  height: "500px",
};

function ListingMap({ address }) {
  const [reload, setReload] = React.useState(true);
  const [center, setCenter] = React.useState({ lat: 0, lng: 0 });

  useEffect(() => {
    Geocode.setApiKey(googleMapsApiKey);
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter({ lat, lng });
      },
      (error) => {
        console.error("Geocode", error);
      }
    );
  }, [address]);
  return (
    <>
      {center.lat !== 0 && center.lng !== 0 && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          id={"38b00cc48cce883"}
        >
          <Marker
            icon={{
              path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
              fillColor: "yellow",
              fillOpacity: 0.9,
              scale: 2,
              strokeColor: "gold",
              strokeWeight: 2,
            }}
            position={center}
          />
        </GoogleMap>
      )}
    </>
  );
}

export default React.memo(ListingMap);
