import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/pages/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import ListingMap from "./listings/ListingMap";
import { ApiURL } from "../config";

const DirectoryListing = () => {
  const { listingId } = useParams();
  const [listing, setListing] = useState([]);
  const [listingImages, setListingImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(true);
  const [tmpImage, setTmpImage] = useState("");

  const getListing = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ listingId: listingId }),
    };
    fetch(`${ApiURL}/listing`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.listing !== false) {
          setListing(data.listing);

          let listingImages = [];
          if (data.listing["image"]) listingImages.push(data.listing["image"]);
          for (let i = 1; i <= 10; i++) {
            if (data.listing["image" + i])
              listingImages.push(data.listing["image" + i]);
          }
          setListingImages(listingImages);
          setLoading(false);
        } else {
          setActive(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getListing();
  }, []);

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/bg-1.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="content">
          <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
            <div className="col-12">
              <h1 className="fw-bold text-white text-center py-5 my-5">
                {listing.name}
              </h1>
            </div>
            <div className="col-12 bg-light rounded mx-4">
              <Breadcrumb />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <Link to="/d/pinellas" className="btn btn-success">
                Back to List
              </Link>
            </div>
          </div>
          <div className="row">
            {active ? (
              <div className="col-12 p-0 m-0">
                {loading ? (
                  <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-5">
                          <img
                            className="rounded-1 img-fluid"
                            src={tmpImage ? tmpImage : listing.image}
                            alt=""
                          />
                          <div className="container">
                            <div className="row">
                              {listingImages.map((image, i) => {
                                if (image) {
                                  return (
                                    <div
                                      className="col p-0"
                                      style={{ maxWidth: "110px" }}
                                      key={`image-${i}`}
                                    >
                                      <button
                                        className="btn-link btn  p-1 m-0"
                                        onClick={() => setTmpImage(image)}
                                      >
                                        <img
                                          className="rounded-1 img-fluid"
                                          src={image}
                                          style={{
                                            maxWidth: "100px",
                                          }}
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  );
                                } else return null;
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-7">
                          <h2 className="fw-bold">{listing.name}</h2>
                          <span>{listing.address}</span>
                          <br />
                          <a href={`tel:${listing.phone}`}>{listing.phone}</a>
                          <br />
                          {listing.website && (
                            <>
                              <a
                                href={listing.website}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {listing.website}
                              </a>
                              <br />
                            </>
                          )}

                          <div className="my-4">
                            {listing.address && (
                              <a
                                href={`https://www.google.com/maps/dir//${encodeURIComponent(
                                  listing.name + " " + listing.address
                                )}`}
                                target="_blank"
                                className="btn btn-md btn-outline-warning fs--1 mx-1"
                                rel="noreferrer"
                              >
                                <span className="fas fa-map-marker-alt"></span>
                                <span className="ms-1 d-none d-lg-inline">
                                  Get Directions
                                </span>
                              </a>
                            )}
                            {listing.website && (
                              <a
                                href={listing.website}
                                target="_blank"
                                className="btn btn-md btn-primary fs--1 mx-1"
                              >
                                <span className="fas fa-globe"></span>
                                <span className="ms-1 d-none d-lg-inline">
                                  Visit Website
                                </span>
                              </a>
                            )}
                          </div>
                          <p className="mb-0">{listing.description}</p>

                          <div className="fs--1 text-600 mt-4">
                            {listing?.facebook && (
                              <a
                                className="btn btn-link m-0 p-0"
                                href={listing.facebook}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="img-fluid me-1"
                                  src="/assets/img/logos/facebook.png"
                                  alt=""
                                  style={{
                                    maxWidth: "30px",
                                    maxHeight: "30px",
                                  }}
                                />
                              </a>
                            )}
                            {listing?.twitter && (
                              <a
                                className="btn btn-link m-0 p-0"
                                href={listing?.twitter}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="img-fluid me-1"
                                  src="/assets/img/logos/twitter.png"
                                  alt=""
                                  style={{
                                    maxWidth: "30px",
                                    maxHeight: "30px",
                                  }}
                                />
                              </a>
                            )}
                            {listing?.youtube && (
                              <a
                                className="btn btn-link m-0 p-0"
                                href={listing?.youtube}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="img-fluid me-1"
                                  src="/assets/img/logos/youtube.png"
                                  alt=""
                                  style={{
                                    maxWidth: "30px",
                                    maxHeight: "30px",
                                  }}
                                />
                              </a>
                            )}
                            {listing?.instagram && (
                              <a
                                className="btn btn-link m-0 p-0"
                                href={listing?.instagram}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="img-fluid me-1"
                                  src="/assets/img/icons/instagram.png"
                                  alt=""
                                  style={{
                                    maxWidth: "30px",
                                    maxHeight: "30px",
                                  }}
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                      {listing.address && (
                        <div className="row">
                          <div className="col-12">
                            <ListingMap address={listing.address} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="col-12 p-0 m-0">
                Listing either does not exist or is not active.
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DirectoryListing;
