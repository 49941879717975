import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { googleMapsApiKey } from "./config";
import { LoadScript } from "@react-google-maps/api";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <LoadScript googleMapsApiKey={googleMapsApiKey} id={"38b00cc48cce883"}>
      <App />
    </LoadScript>
);
