import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ApiURL, stripeKey } from "../../../config";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SignUpCheckoutForm from "./SignUpCheckoutForm";

const stripePromise = loadStripe(stripeKey);

function SignUpPayment({ processSubscription, backStep, price, data }) {
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);

  const loadPaymentIntent = () => {
    setLoading(true);
    fetch(ApiURL + "/stripe/paymentIntent", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!json.status) {
          setLoading(false);
        } else {
          setLoading(false);
          setClientSecret(json.clientSecret);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });

    // setSubscriptionTypes
  };
  useEffect(() => {
    loadPaymentIntent();
  }, []);

  const options = {
    clientSecret: clientSecret,
  };

  if (loading) {
    return "loading";
  }

  return (
    <>
      <Helmet>
        <title>Pinellas Christian Businesses | Account Payment Method</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 pt-4 mb-4 text-center">
                  {clientSecret && (
                    <Elements stripe={stripePromise} options={options}>
                      <SignUpCheckoutForm
                        subscriptionId={data.subscriptionId}
                      />
                    </Elements>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpPayment;
