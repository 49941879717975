import React, { useContext, useEffect, useState } from "react";
import FormInput from "../../components/form/FormInput";
import Loading from "../../components/layouts/Loader";
import { ApiURL, auth, token } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import { AuthTokenContext } from "../../App";
import { checkAuth } from "../../components/helpers/authHelper";

const Password = () => {
  const [editMode, setEditMode] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  const [originalPassword, setOriginalPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setEditMode(true);
      }, 3000);
    }
  }, [successMessage]);

  const submitForm = (e) => {
    e.preventDefault();
    let errorsFound = [];
    let error = false;
    if (!originalPassword) {
      errorsFound.originalPassword = "Field is required and can not be blank";
      error = true;
    }
    if (!password) {
      errorsFound.password = "Field is required and can not be blank";
      error = true;
    }
    if (!passwordConfirm) {
      errorsFound.passwordConfirm = "Field is required and can not be blank";
      error = true;
    }
    if (passwordConfirm !== password) {
      errorsFound.password = "Passwords do not match!";
      errorsFound.passwordConfirm = "Passwords do not match!";
      error = true;
    }

    if (error) {
      setErrors(errorsFound);
    } else {
      setErrors([]);
      saveChanges();
    }
  };

  const saveChanges = () => {
    setButtonLoading(true);
    let data = {
      originalPassword,
      password,
      passwordConfirm,
    };
    fetch(`${ApiURL}/savePassword`, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setButtonLoading(false);

        if (res.status === true) {
          setErrors([]);
          setSuccessMessage(res.message);
          setPassword("");
          setOriginalPassword("");
          setPasswordConfirm("");
        } else {
          console.log("fail 2", res);
          setErrors(res.fields);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  const AuthToken = useContext(AuthTokenContext);

  const checkUserAuth = async () => {
    setLoading(true);
    let auth = await checkAuth(AuthToken.authToken);
    setLoading(false);
    if (!auth) {
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    if (AuthToken.authToken) {
      checkUserAuth();
    } else {
      navigate("/auth/login");
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body">
              <h1>Update Password</h1>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  <div className="alert-success">{successMessage}</div>
                </div>
              )}
              <div className="container">
                <div className="row">
                  <FormInput
                    id="originalPassword"
                    label="Current Password"
                    name="originalPassword"
                    value={originalPassword}
                    type="password"
                    colSize={3}
                    onChange={(type, e, field) =>
                      setOriginalPassword(e.target.value)
                    }
                    required={true}
                    error={errors?.originalPassword ?? null}
                  />
                  <FormInput
                    id="password"
                    label="New Password"
                    name="password"
                    value={password}
                    type="password"
                    colSize={3}
                    onChange={(type, e, field) => setPassword(e.target.value)}
                    required={true}
                    error={errors?.password ?? null}
                  />
                  <FormInput
                    id="passwordConfirm"
                    label="Re-enter New Password"
                    name="passwordConfirm"
                    value={passwordConfirm}
                    type="password"
                    colSize={3}
                    onChange={(type, e, field) =>
                      setPasswordConfirm(e.target.value)
                    }
                    required={true}
                    error={errors?.passwordConfirm ?? null}
                  />
                </div>
                {editMode && (
                  <div className="row mt-2">
                    <div className="col-6">
                      <Link className="btn btn-outline-secondary" to="/account">
                        cancel
                      </Link>
                    </div>
                    <div className="col-6 text-end">
                      <button className="btn btn-success" onClick={submitForm}>
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Password;
