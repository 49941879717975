import React, { useEffect } from "react";
import Listing from "./Listing";
import FeaturedListing from "./FeaturedListing";
import Pagination from "../../components/pages/Pagination";
import { Link } from "react-router-dom";

const Listings = ({ filteredListings, getListings, featuredListings }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [limitPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(
    Math.ceil(filteredListings.length / limitPerPage)
  );
  const [recordsStart, setRecordStart] = React.useState(0);
  const [recordsEnd, setRecordEnd] = React.useState(limitPerPage);

  const changePage = (page) => {
    setCurrentPage(page);
    let start = (page - 1) * limitPerPage;
    let end = start + limitPerPage;
    setRecordStart(start);
    setRecordEnd(end);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredListings.length / limitPerPage));
    changePage(1);
  }, [filteredListings.length]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 my-4">
            <h3 className="text-white text-center">Featured Businesses</h3>
          </div>
          {featuredListings &&
            featuredListings.length > 0 &&
            featuredListings.map((listing, index) => {
              if (index > 2) return null;
              return (
                <div
                  className="col-4 m-0 ps-0 pe-2"
                  key={`featured-listing${index}`}
                >
                  <FeaturedListing listing={listing} />
                </div>
              );
            })}
        </div>
        <div className="row my-4">
          <div className="col-6">
            <h3 className="text-white">
              Businesses Found: {filteredListings.length}
            </h3>
          </div>
          <div className="col-6 text-end">
            <Link to="/content/signup" className="btn btn-warning btn-sm">
              Sign My Business Up
            </Link>
          </div>
        </div>
      </div>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onUpdatePage={changePage}
        />
      )}
      {filteredListings.map((listing, index) => {
        if (index < recordsStart || index >= recordsEnd) return null;
        return <Listing listing={listing} key={`listing${index}`} />;
      })}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onUpdatePage={changePage}
        />
      )}
    </>
  );
};
export default Listings;
