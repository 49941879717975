import React, { createContext, useCallback, useMemo, useState } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import ScrollToTop from "./components/layouts/ScrollToTop";
import Layout from "./components/layouts/Layout";
import Landing from "./pages/Landing";
import Error404 from "./pages/404";
import Pricing from "./pages/Pricing";
import FAQs from "./pages/FAQs";
import Contact from "./pages/Contact";
import SignUp from "./pages/product/signup";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import AuthLayout from "./components/layouts/AuthLayout";
import LostPassword from "./pages/auth/LostPassword";
import Dashboard from "./pages/account/Dashboard";
import Billing from "./pages/account/Billing";
import Subscription from "./pages/account/Subscription";
import ProfileNav from "./components/layouts/ProfileNav";
import Profile from "./pages/account/Profile";
import Support from "./pages/account/Support";
import Password from "./pages/account/Password";
import DirectoryList from "./pages/DirectoryList";
import DirectoryListing from "./pages/DirectoryListing";
import Directory from "./pages/Directory";
import Blank from "./pages/Content";
import Content from "./pages/Content";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        index
        element={
          <Layout>
            <Landing />
          </Layout>
        }
      />

      <Route path="d" element={<Layout />}>
        <Route index element={<Directory />} />
        <Route path=":locationName" element={<DirectoryList />} />
        <Route
          path=":locationName/:listingName/:listingId"
          element={<DirectoryListing />}
        />
      </Route>
      <Route path="content" element={<Layout />}>
        <Route path="about" element={<Content />} />
        <Route path="contact" element={<Contact />} />
        <Route path="faqs" element={<FAQs />} />

        <Route path="terms" element={<Content />} />
        <Route path="privacy" element={<Content />} />

        <Route path="pricing" element={<Pricing />} />
        <Route path="signup">
          <Route index element={<Pricing />} />
          <Route path=":subscriptionType" element={<SignUp />} />
          <Route path=":subscriptionType/:page" element={<SignUp />} />
        </Route>
      </Route>

      <Route
        path="account"
        element={
          <Layout>
            <ProfileNav />
          </Layout>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="billing" element={<Billing />} />
        <Route path="password" element={<Password />} />
        <Route path="profile" element={<Profile />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="support" element={<Support />} />
      </Route>

      <Route path="auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="lost-password" element={<LostPassword />} />
      </Route>

      <Route path="*" element={<Error404 />} />
    </Route>
  ),
  {
    basename: "/",
  }
);

export const AuthTokenContext = createContext(null);

function App() {
  const [authToken, setAuthToken] = useState(null);
  const [userInfo, setUserInfo] = useState("");

  const login = useCallback((token) => {
    setAuthToken(token);
  }, []);
  const setUserInfoCallback = useCallback((userInfoData) => {
    setUserInfo(userInfoData);
  }, []);

  const contextValue = useMemo(
    () => ({
      authToken,
      userInfo,
      setUserInfoCallback,
      login,
    }),
    [authToken, login, userInfo, setUserInfoCallback]
  );

  return (
    <AuthTokenContext.Provider value={contextValue}>
      <RouterProvider router={router}>
        <ScrollToTop />
      </RouterProvider>
    </AuthTokenContext.Provider>
  );
}

export default App;
