import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const FeaturedListing = ({ listing }) => {
  return (
    <article className="card overflow-hidden">
      <div className="card-body p-0">
        <div className="hoverbox h-md-100">
          <Link
            className="text-decoration-none"
            to={`/d/pinellas/${listing.slug}/${listing.id}`}
            data-gallery="attachment-bg"
          >
            <img
              className="h-100 w-100 object-fit-cover"
              src={listing.image}
              alt=""
            />
          </Link>
          <div className="hoverbox-content flex-center pe-none bg-holder overlay overlay-2">
            <img
              className="z-1"
              src="/assets/img/icons/spot-illustrations/location.svg"
              width="60"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="col-12 p-x1" style={{ minHeight: "150px" }}>
        <h4 className="mt-3 mt-sm-0 fs-0 fs-lg-1">
          <Link
            className="text-900"
            to={`/d/pinellas/${listing.slug}/${listing.id}`}
          >
            {listing.name}
          </Link>
        </h4>
        <span>{listing.address}</span>
        <br />
        <span>{listing.phone}</span>
        <br />

        <Link
          to={`/d/pinellas/${listing.slug}/${listing.id}`}
          className="btn btn-md btn-primary mt-2 fs--1"
        >
          <span className="fas fa-th-list"></span>
          <span className="ms-1 d-none d-lg-inline">More</span>
        </Link>
      </div>
    </article>
  );
};
export default FeaturedListing;
