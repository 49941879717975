import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiURL } from "../config";
// import { subscriptionTypes } from "../config";

const PricingInfo = ({ subscriptionInfo, processUpgrade }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);

  const [subscriptionLevel] = useState(
    parseInt(subscriptionInfo?.subscriptionTypeId)
      ? parseInt(subscriptionInfo?.subscriptionTypeId)
      : 0
  );
  const [additionalPrice] = useState(0);
  const [originalCost] = useState(0);

  const loadSubscriptionTypes = () => {
    fetch(`${ApiURL}/subscription-types`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setSubscriptionTypes(data.subscriptionTypes);
          setLoading(false);
        }
      });
  };

  const upgradeSubscription = (currentSubscriptionLevel) => {
    processUpgrade(currentSubscriptionLevel);
  };

  const purchaseSubscription = (subscriptionLevel) => {
    let path = "/content/signup/";

    path = path + subscriptionLevel;

    navigate(path);
  };

  useEffect(() => {
    loadSubscriptionTypes();
  }, []);

  if (loading) {
    return (
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }
  return (
    <>
      {subscriptionTypes?.map((sub, index) => {
        return (
          <div className="col-6" key={`subscription${index}`}>
            <div
              className={`rounded-3 overflow-hidden mb-3 mb-md-0 ${
                subscriptionLevel === sub.id ? "borderPrimary" : ""
              }`}
            >
              <div className="bg-dark d-flex flex-between-center p-4">
                <div>
                  <h3 className="fw-light fs-5 mb-0 text-primary">
                    {sub.name}
                  </h3>
                  {subscriptionLevel !== 1 && (
                    <h2 className="fw-light mt-0 text-primary">
                      <sup className="fs-1">$</sup>
                      <span className="fs-3">{sub.price}</span>
                      <span className="fs--2 mt-1"> / yr</span>
                    </h2>
                  )}
                  {subscriptionLevel === 1 && (
                    <>
                      <h2 className="fw-light mt-0 text-primary">
                        {subscriptionLevel === sub.id &&
                          additionalPrice + sub.startingPrice !==
                            originalCost && (
                            <>
                              <sup className="fs-1">Original Cost $</sup>
                              <span className="fs-3">{originalCost}</span>
                              <span className="fs--2 mt-1"> / mo</span>
                              <br />
                            </>
                          )}
                        <sup className="fs-1">
                          {additionalPrice + sub.startingPrice !== originalCost
                            ? "Upgrade Cost "
                            : ""}
                          $
                        </sup>
                        <span className="fs-3">
                          {additionalPrice + sub.startingPrice}
                        </span>
                        <span className="fs--2 mt-1"> / yr</span>
                      </h2>
                    </>
                  )}
                </div>
              </div>
              <div className="p-4 bg-light">
                <ul className="list-unstyled">
                  {sub.features.map((feature, index) => {
                    return (
                      <li
                        className="border-bottom py-2"
                        key={`subFeature${index}`}
                      >
                        <span
                          className="fas fa-check text-primary"
                          data-fa-transform="shrink-2"
                        >
                          {" "}
                        </span>{" "}
                        {feature}
                      </li>
                    );
                  })}
                </ul>
                <SubscriptionButton
                  subscriptionLevel={subscriptionLevel}
                  currentSubscriptionLevel={sub.id}
                  upgradeSubscription={upgradeSubscription}
                  price={additionalPrice + sub.startingPrice}
                  purchaseSubscription={purchaseSubscription}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PricingInfo;

const SubscriptionButton = ({
  subscriptionLevel,
  currentSubscriptionLevel,
  upgradeSubscription,
  price,
  purchaseSubscription,
}) => {
  if (currentSubscriptionLevel === 2) {
    return (
      <button
        className="btn btn-secondary disabled d-block w-100"
        type="button"
      >
        Coming Soon
      </button>
    );
  }
  if (currentSubscriptionLevel === 3) {
    return (
      <button
        className="btn btn-secondary disabled d-block w-100"
        type="button"
      >
        Coming Soon
      </button>
    );
  }
  if (subscriptionLevel) {
    if (subscriptionLevel === currentSubscriptionLevel) {
      return (
        <button
          className="btn btn-primary d-block w-100"
          type="button"
          onClick={() => upgradeSubscription(currentSubscriptionLevel)}
        >
          Upgrade
        </button>
      );
    }
    return (
      <button
        className="btn btn-secondary disabled d-block w-100"
        type="button"
      >
        Coming Soon
      </button>
    );
  }
  return (
    <button
      className="btn btn-primary d-block w-100"
      type="button"
      onClick={() => purchaseSubscription(currentSubscriptionLevel, price)}
    >
      Get Started
    </button>
  );
};
