import React, { useEffect, useState } from "react";
import { sortByName } from "../components/helpers/textHelper";
import ListingNav from "./listings/ListingNav";
import Listings from "./listings/Listings";
import { ApiURL, configFilters, configListings } from "../config";
import Breadcrumb from "../components/pages/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";

const DirectoryList = () => {
  const { locationName } = useParams();
  const [loading, setLoading] = useState(true);

  const [backgroundImage, setBackgroundImage] = React.useState(
    "/assets/img/backgrounds/florida-2669193_1920.jpg"
  );
  const [location, setLocation] = useState({});
  const [filters, setFilters] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState(listings);
  const [featuredListings, setFeaturedListings] = useState([]);

  useEffect(() => {
    if (selectedFilters.length === 0) return setFilteredListings(listings);

    console.log("selectedFilters", selectedFilters);
    setFilteredListings(
      listings.filter((listing) => {
        return selectedFilters.some((filter) =>
          listing.tags.includes(filter.id)
        );
      })
    );

    setFeaturedListings(
      listings.filter((listing) => listing.featured === true)
    );
  }, [selectedFilters]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getLocation();
  }, []);

  const filterToggle = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== filter));
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const resetFilters = () => {
    setSelectedFilters([]);
  };

  const updateTags = (tags) => {
    tags.sort(sortByName);
    setFilters(tags);
  };

  const getLocation = () => {
    fetch(`${ApiURL}/location`, {
      method: "POST",
      body: JSON.stringify({
        slug: locationName,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setLoading(false);
          updateTags(data.tags);
          setListings(data.listings);
          setLocation(data.location);
          resetFilters();
        }
      });
  };

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center top",
          backgroundAttachment: "fixed",
        }}
      />
      <div className="container">
        <div className="content">
          <div className="row flex-top pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
            <div className="col-12 bg-light rounded mb-3 p-4">
              <h1 className="fw-bold">{location.pageTitle}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 bg-light rounded mb-4 mt-3">
              <Breadcrumb />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 bg-light rounded">
              <ListingNav
                resetFilters={resetFilters}
                filters={filters}
                filterToggle={filterToggle}
                selectedFilters={selectedFilters}
              />
            </div>
            {loading ? (
              <div className="col-xl-9">
                <div className="spinner-border text-warning" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="col-xl-9">
                <Listings
                  filteredListings={filteredListings}
                  featuredListings={featuredListings}
                  getListings={getLocation}
                />
              </div>
            )}
          </div>
          <div className="row pt-4">
            <div className="col-12 bg-light rounded mb-3 p-4">
              <h1 className="fw-bold text-center">{location.pageTitle}</h1>
              <div
                  dangerouslySetInnerHTML={{ __html: location.pageDescription }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DirectoryList;
