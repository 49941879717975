import React, { useContext, useEffect, useState } from "react";
import FormInput from "../../components/form/FormInput";
import Loading from "../../components/layouts/Loader";
import { ApiURL, auth, token } from "../../config";
import { useNavigate } from "react-router-dom";
import { AuthTokenContext } from "../../App";
import { checkAuth } from "../../components/helpers/authHelper";

const Profile = () => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [personalPhone, setPersonalPhone] = useState("");
  const [personalAddress1, setPersonalAddress1] = useState("");
  const [personalAddress2, setPersonalAddress2] = useState("");
  const [personalCity, setPersonalCity] = useState("");
  const [personalState, setPersonalState] = useState("");
  const [personalZip, setPersonalZip] = useState("");
  const AuthToken = useContext(AuthTokenContext);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setEditMode(false);
      }, 3000);
    }
  }, [successMessage]);

  const submitForm = (e) => {
    e.preventDefault();
    let errorsFound = [];
    let error = false;
    if (!firstName) {
      errorsFound.firstName = "Field is required and can not be blank";
      error = true;
    }
    if (!lastName) {
      errorsFound.lastName = "Field is required and can not be blank";
      error = true;
    }
    if (!personalPhone) {
      errorsFound.personalPhone = "Field is required and can not be blank";
      error = true;
    }
    if (!personalAddress1) {
      errorsFound.personalAddress1 = "Field is required and can not be blank";
      error = true;
    }
    if (!personalCity) {
      errorsFound.personalCity = "Field is required and can not be blank";
      error = true;
    }
    if (!personalState) {
      errorsFound.personalState = "Field is required and can not be blank";
      error = true;
    }
    if (!personalZip) {
      errorsFound.personalZip = "Field is required and can not be blank";
      error = true;
    }

    if (error) {
      setErrors(errorsFound);
    } else {
      setErrors([]);
      saveChanges();
    }
  };

  const saveChanges = () => {
    setButtonLoading(true);
    let data = {
      firstName,
      lastName,
      personalPhone,
      personalAddress1,
      personalAddress2,
      personalCity,
      personalState,
      personalZip,
    };
    fetch(`${ApiURL}/savePersonal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken.authToken,
      },
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setButtonLoading(false);
        if (res.status === true) {
          setErrors([]);
          setSuccessMessage(res.message);
        } else {
          console.log("fail 2", res);
          setErrors(res.fields);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  const checkUserAuth = async () => {
    setLoading(true);
    let auth = await checkAuth(AuthToken.authToken);
    setLoading(false);
    if (!auth) {
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    if (AuthToken.authToken) {
      checkUserAuth();
    } else {
      navigate("/auth/login");
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body">
              <h1>Personal Information</h1>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  <div className="alert-success">{successMessage}</div>
                </div>
              )}
              <div className="container">
                {!editMode && (
                  <div className="row">
                    <div className="col-12 text-end">
                      <button
                        className="btn btn-outline-secondary"
                        onClick={toggleEditMode}
                      >
                        edit
                      </button>
                    </div>
                  </div>
                )}
                <div className="row">
                  <FormInput
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={firstName}
                    type={editMode ? "text" : "display"}
                    colSize={3}
                    onChange={(type, e, field) => setFirstName(e.target.value)}
                    required={true}
                    error={errors?.firstName ?? null}
                  />
                  <FormInput
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    value={lastName}
                    type={editMode ? "text" : "display"}
                    colSize={3}
                    onChange={(type, e, field) => setLastName(e.target.value)}
                    required={true}
                    error={errors?.lastName ?? null}
                  />
                  <FormInput
                    id="lastName"
                    label="Phone"
                    name="personalPhone"
                    value={personalPhone}
                    type={editMode ? "text" : "display"}
                    colSize={3}
                    onChange={(type, e, field) =>
                      setPersonalPhone(e.target.value)
                    }
                    required={true}
                    error={errors?.personalPhone ?? null}
                  />
                  <FormInput
                    id="lastName"
                    label="Address"
                    name="personalAddress1"
                    value={personalAddress1}
                    type={editMode ? "text" : "display"}
                    colSize={2}
                    onChange={(type, e, field) =>
                      setPersonalAddress1(e.target.value)
                    }
                    required={true}
                    error={errors?.personalAddress1 ?? null}
                  />
                  <FormInput
                    id="lastName"
                    label="Address cont."
                    name="personalAddress2"
                    value={personalAddress2}
                    type={editMode ? "text" : "display"}
                    colSize={2}
                    onChange={(type, e, field) =>
                      setPersonalAddress2(e.target.value)
                    }
                    error={errors?.personalAddress2 ?? null}
                  />
                  <FormInput
                    id="lastName"
                    label="City"
                    name="personalCity"
                    value={personalCity}
                    type={editMode ? "text" : "display"}
                    colSize={3}
                    onChange={(type, e, field) =>
                      setPersonalCity(e.target.value)
                    }
                    required={true}
                    error={errors?.personalCity ?? null}
                  />
                  <FormInput
                    id="lastName"
                    label="State"
                    name="personalState"
                    value={personalState}
                    type={editMode ? "state" : "display"}
                    colSize={3}
                    onChange={(type, e, field) =>
                      setPersonalState(e.target.value)
                    }
                    required={true}
                    error={errors?.personalState ?? null}
                  />
                  <FormInput
                    id="lastName"
                    label="Zip"
                    name="personalZip"
                    value={personalZip}
                    type={editMode ? "text" : "display"}
                    colSize={3}
                    onChange={(type, e, field) =>
                      setPersonalZip(e.target.value)
                    }
                    required={true}
                    error={errors?.personalZip ?? null}
                  />
                </div>
                {editMode && (
                  <div className="row mt-2">
                    <div className="col-6">
                      <button
                        className="btn btn-outline-secondary"
                        onClick={toggleEditMode}
                      >
                        cancel
                      </button>
                    </div>
                    <div className="col-6 text-end">
                      {buttonLoading ? (
                        <div
                          className="spinner-border text-warning"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={submitForm}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
