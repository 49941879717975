import { Link } from "react-router-dom";

const LayoutNav = () => {
  return (
    <nav
      className="navbar navbar-standard navbar-expand-lg fixed-top navbar-dark bg-dark"
      data-navbar-darken-on-scroll="data-navbar-darken-on-scroll"
    >
      <div className="container">
        <Link className="navbar-brand" to={"/"}>
          <span className="text-white dark__text-white">
            PinellasChristianBusinesses
          </span>
        </Link>

        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarStandard"
          aria-controls="navbarStandard"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse scrollbar" id="navbarStandard">
          <ul
            className="navbar-nav"
            data-top-nav-dropdowns="data-top-nav-dropdowns"
          >
            <li className="nav-item">
              <Link
                className="nav-link"
                to={"/"}
                role="button"
                data-bs-toggle="dropdown"
                id="dashboards"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to={"/d/pinellas"}
                role="button"
                data-bs-toggle="dropdown"
                id="apps"
              >
                Directory
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to={"/content/pricing"}
                role="button"
                data-bs-toggle="dropdown"
                id="apps"
              >
                Get Listed
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to={"/content/faqs"}
                role="button"
                data-bs-toggle="dropdown"
                id="apps"
              >
                FAQs
              </Link>
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
            <li className="nav-item">
              <Link className="nav-link pe-0 ps-2" to="/account">
                <div className="avatar avatar-xl">
                  <img
                    className="rounded-circle bg-white"
                    src="/assets/img/team/avatar.png"
                    alt="View Account"
                  />
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default LayoutNav;
