import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ApiURL, auth, token } from "../../config";

const Logout = () => {
  const logOut = () => {
    fetch(`${ApiURL}/auth/logout`, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {})
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  useEffect(() => {
    logOut();
  });

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="dark">
      <div className="container-fluid">
        <div className="row min-vh-100 bg-100">
          <div className="col-6 d-none d-lg-block position-relative">
            <div
              className="bg-holder"
              style={{
                backgroundImage: "url(../../../assets/img/generic/19.jpg)",
              }}
            />
          </div>
          <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
            <div className="row justify-content-center g-0">
              <div className="col-lg-9 col-xl-8 col-xxl-6">
                <div className="card">
                  <div className="card-header bg-circle-shape bg-shape text-center p-2">
                    <h4
                      className="font-sans-serif fw-bolder fs-4 z-1 position-relative link-light"
                      data-bs-theme="light"
                    >
                      Pinellas Christian Businesses
                    </h4>
                  </div>
                  <div className="card-body p-4">
                    <div className="text-center">
                      <img
                        className="d-block mx-auto mb-4"
                        src="/assets/img/icons/spot-illustrations/45.png"
                        alt="shield"
                        width="100"
                      />
                      <h3>See you again!</h3>
                      <p>
                        Thanks for using pinellaschristianbusinesses.com You are{" "}
                        <br />
                        now successfully signed out.
                      </p>
                      <Link
                        className="btn btn-primary btn-sm mt-3"
                        to="/auth/login"
                      >
                        <span
                          className="fas fa-chevron-left me-1"
                          data-fa-transform="shrink-4 down-1"
                        ></span>
                        Return to Login
                      </Link>
                      <br />
                      <br />
                      <Link className="btn btn-secondary btn-sm mt-3" to="/">
                        Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Logout;
