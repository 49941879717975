import React from "react";
import LayoutNav from "./LayoutNav";
import { Outlet } from "react-router-dom";
import LayoutFooter from "./LayoutFooter";
const Layout = ({ children }) => {
  return (
    <main className="main" id="top">
      <LayoutNav />
      {!children && <Outlet />}
      {children}
      <LayoutFooter />
    </main>
  );
};
export default Layout;
