import React from "react";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <>
      <section
        className="py-0 overflow-hidden"
        id="banner"
        data-bs-theme="light"
      >
        <div
          className="bg-holder overlay"
          style={{
            backgroundImage:
              "url(/assets/video/home-bg.jpg)",
            backgroundPosition: "center bottom",
          }}
        >
          <video
            className="bg-video"
            autoPlay="autoplay"
            loop="loop"
            muted="muted"
            playsInline="playsinline"
          >
            <source src="/assets/video/home-bg.mp4" type="video/mp4" />
            <source src="/assets/video/home-bg.webm" type="video/webm" />
          </video>
        </div>
        <div className="container">
          <div className="row flex-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
            <div className="col-md-11 col-lg-8 col-xl-4 pb-7 pb-xl-9 text-center text-xl-start ">
              <div className="bg-dark bg-opacity-50 rounded p-3 border-radius-5">
                <h1 className="text-white fw-light">
                  Pinellas Christian Businesses
                </h1>
                <p className="lead text-white opacity-75">
                  Pinellas County is a vibrant and diverse region located on the
                  west coast of Florida, along the Gulf of Mexico. With its mild
                  year-round climate, beautiful beaches, and abundant natural
                  attractions, Pinellas County is a popular destination for
                  tourists and residents alike.
                </p>
                <center>
                <Link
                    className="btn btn-outline-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2"
                    to="/d/pinellas"
                >
                  Search Listings
                  <span
                      className="fas fa-play ms-2"
                      data-fa-transform="shrink-6 down-1"
                  />
                </Link>
                </center>
              </div>
            </div>
            <div className="col-xl-7 offset-xl-1  mt-xl-0">
              <Link
                className="img-landing-banner rounded"
                to="/product/pricing"
              >
                <img
                  className="img-fluid"
                  src="/assets/img/screenshot.PNG"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light text-center">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="fs-2 fs-sm-4 fs-md-5">
                What our customers are saying
              </h1>
            </div>
          </div>
          <div className="row mt-6">
            <div className="col-lg-4">
              <div className="card card-span h-100">
                <div className="card-span-img">
                  <img
                    src="/assets/img/team/3.jpg"
                    className="img-fluid rounded"
                    alt="Sarah M."
                  />
                </div>
                <div className="card-body pt-6 pb-4">
                  <h5 className="mb-2">Sarah M.</h5>
                  <p>
                    I've been using this company for over a year now and their
                    online document security solutions have been a game-changer
                    for my business. I feel confident knowing that my
                    confidential information is safe and secure, and their
                    customer service is exceptional.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-6 mt-lg-0">
              <div className="card card-span h-100">
                <div className="card-span-img">
                  <img
                    src="/assets/img/team/2.jpg"
                    className="img-fluid rounded"
                    alt="John D."
                  />
                </div>
                <div className="card-body pt-6 pb-4">
                  <h5 className="mb-2">John D.</h5>
                  <p>
                    I was skeptical at first, but after using their services for
                    a few months, I'm blown away by how easy and effective it is
                    to secure my documents online. I highly recommend this
                    company to anyone who wants to protect their sensitive
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-6 mt-lg-0">
              <div className="card card-span h-100">
                <div className="card-span-img">
                  <img
                    src="/assets/img/team/1.jpg"
                    className="img-fluid rounded"
                    alt="Rachel T."
                  />
                </div>
                <div className="card-body pt-6 pb-4">
                  <h5 className="mb-2">Rachel T.</h5>
                  <p>
                    As someone who works in the legal field, document security
                    is crucial. This company has exceeded my expectations and
                    has provided me with peace of mind knowing that my client's
                    documents are always protected. Their software is
                    user-friendly and their team is always available to answer
                    any questions I have. I highly recommend this company!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-dark" data-bs-theme="light">
        <div
          className="bg-holder overlay"
          style={{
            backgroundImage: "url(../assets/img/generic/bg-2.jpg)",
            backgroundPosition: "center top",
          }}
        ></div>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <p className="fs-3 fs-sm-4 text-white">
                Join our community of businesses on their mission to serve and
                support other christian run businesses.
              </p>
              <Link
                className="btn btn-outline-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2"
                type="button"
                to="/content/pricing"
              >
                Sign Up Today
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Landing;
