import { ApiURL, auth } from "../../config";

export const checkAuth = (token) => {
  console.log("helper AuthToken", token);
  return fetch(`${ApiURL}/auth/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      console.log("authLogin Error", err);
    });
};
